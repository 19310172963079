@import '~aes-lesson-driver/src/variables';
@import '~aes-lesson-driver/src/mixins';

.form-link-creator {
  text-align: left;
  label, span {
    margin: $gutter/2;
    input {
      margin-left: $gutter/2
    }
  }
  section.picker{
    .option-list{
      padding: $gutter/2 0;
    }
  }

  .selected-form{
    padding: $gutter $gutter/2;
  }

  .actions{
    text-align: center;
    button.save{
      justify-self: flex-end;
      @include button();
    };
  }

}
