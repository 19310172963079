@import '../variables';
@import '../mixins';

.page-content {
  background: $white;
  .main,
  .main > section {
    padding: $gutter / 2;
  }

  section {
    box-sizing: border-box;
  }

  section.media {
    img {
      border: 1px solid $mediumGrey
    }
  }

  section.drag-drop {
    h3 {
      margin: $gutter / 2;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none outside none;

      li {
        margin: $gutter / 2;
        padding: $gutter / 2;
        background: $white;
        border: 1px solid $border;
        cursor: pointer;
        &.dragging{
          opacity: 0.3;
        }
      }
    }
    .figure-wrapper {
      position: relative;
      display: inline-block;
      .target{
        position: absolute;
        transform: translate(-50%, -50%);
        border:2px solid black;
        span{
          display: block;
          width: $gutter;
          height: $gutter;
          border: 2px solid $yellow;
          font-size: 0;
          color: transparent;
        }
        &.answered {
          display: none;
        }
      }
      .term{
        position: absolute;
        transform: translate(-50%, -50%);
        list-style: none outside none;
        color: $yellow;
        font-weight: bold;
        text-shadow:
          1px 1px 0 black,
          1px -1px 0 black,
          -1px 1px 0 black,
          -1px -1px 0 black;
      }
    }

    section.terms {
      margin: $gutter / 2;
      border: 1px solid $border;
      background: $questionBackground;
      &.label{

      }
      &.categorize {
        ul li {
            display: inline-block;
          }
        }
      &.sequence {
        ul li.answered {
          border: 2px solid $aesGreen;
          color: $aesGreen;
        }
      }
    }

    section.categories{
      .category > div{
        display: flex;
        flex-direction: column;
        min-height: 100%;
        border: 1px solid $border;
        background: $questionBackground;
        ul {
          min-height: $gutter * 2;
          flex-grow: 1;
        }
      }
    }

    section.definitions {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      align-self: flex-start;
      > div {
        min-width: 20%;
        max-width: 33%;
        padding: $gutter/2;
        box-sizing: border-box;

        .definition {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-height: 100%;
          box-sizing: border-box;
          padding: $gutter/2;
          background: $questionBackground;
          border: 1px solid $border;

          p{
            margin: $gutter/2 0;
          }

          > .term {
            min-height: 40px;
            margin: $gutter/2 0;
            background: $white;

            div {
              padding: $gutter / 2;
            }
          }
        }
      }
    }
  }

  section.text,
  section.questions{
    text-align: left;
    h1 {
      margin: initial;
      padding: initial;
      color: initial;
      background: initial;
      font-size: 1.5em;
    }
    h2 {
      font-size: 1.2em;
    }

    p {
      margin: 1em 0 .5em;
    }

    ul, ol {
      margin-top: .25em;
      margin-bottom: .25em;
      li {
        margin-bottom: .25em;
        ol, ul {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }

  section.questions {
    .question {
      margin: $gutter auto;
      padding: $gutter / 2;

      h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
      }

      input,
      textarea {
        display: block;
        box-sizing: border-box;
        width: 100%;
        margin-top: $gutter / 3;
      }
    }
  }

  button.glossary-word,
  button.print-form,
  button.show-image,
  section.text a,
  section.text a:visited {
    padding: 2px;
    color: $aesBlue;
    background: none;
    text-decoration: underline;
    border: none;
    font-weight: normal;
    cursor: help;
    i {
      margin: 4px;
    }
  }
  button.print-form{
    cursor: alias;
  }

  button.slide-number {
    margin-left: 2px;
    padding: 0 6px;
    background-color: $buttonYellow;
    font-style: normal;
    font-weight: normal;
    font-size: 0;
    color: transparent;
    &::before{
      content: "\f01e";
      display: inline;
      color: $black;
      font-family: 'Font Awesome 5 Free';
      font-size: .8rem;
    }
    &:hover {
      background-color: $buttonYellowHover;
    }
  }

  button.check-answer {
    display:block;
    margin: $gutter / 2 auto;
  }

  .message {
    padding: $gutter;
    text-align: center;
    &.success{
      color: $aesGreen;
    }
    i {
      margin-left: $gutter / 2;
    }
  }
}
