@import "../mixins";

.curriculum-builder-page {
  header {
    background: $regularBlue;
    color: $white;
    margin: auto;
    padding: $gutter;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-between;

    h1, h2, h3 {
      margin: 0;
    }

    h1 {
      width: 100%;
      margin-bottom: $gutter / 2;
      text-align: left;
    }
  }

  img,
  video {
    max-width: 100%;
  }

  fieldset {
    border: none;
    padding: 0;
  }

  input,
  select,
  textarea{
    display: inline-block;
    padding: $gutter / 2;
    color: $textDark;
    font-size: 1em;
    font-family: $fontFamily;
    background: $white;
    border: 1px solid $border;
  }

  select {
    height: 40px;
  }

  button,
  .button {
    @include button();
  }

  label {
    margin-bottom: 0;
      & > {
      input[type=file] {
        @include sr-only;
      }

      input[type=radio],
      input[type=checkbox] {
        @include sr-only;

        + span {
          display: block;
          position: relative;
          padding-left: 28px;
          line-height: 36px;

          &::before {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            position: absolute;
            left: 0;
            top: 8px;
            background: $white;
            border: 2px solid $mediumGrey;
            border-radius: 20px;
            box-sizing: border-box;
          }
        }

        &:checked + span {
          &::before {
            border-color: $regularBlue;
          }

          &::after {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            position: absolute;
            left: 5px;
            top: 13px;
            background: $regularBlue;
            border-radius: 10px;
          }
        }
        &:disabled + span {
          color: $grey4;
          &::after {
            background: $grey4;
          }
          &::before {
            border-color: $grey4;
          }
        }
      }
      input[type=checkbox] {
        & + span {
          &::before {
            border-radius: 3px;
            border-width: 3px;
          }
        }
        &:checked + span::before{
        }
        &:checked + span::after {
          content: '\f00c';
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          border-radius: 0;
          top: 1px;
          left: 3px;
          background: none;
          color: $regularBlue;
        }
      }
    }
  }

  .flex {
    display: flex;

    > * {
      padding: $gutter/2;
      flex: 1 1 auto;
    }

    @include breakpoint-small {
      display: block;

    }
  }

  @include breakpoint-small() {
    .layout-5050 {
      > section:nth-of-type(1) {
        width: 40%;
        float: right;
      }

      &.question > section:nth-of-type(1) {
        width: auto;
        float: none;
      }
    }
  }

  @include breakpoint-mid() {
    .layout-5050,
    .layout-7525 {
      display: flex;
    }

    .layout-5050 {
      > section,
      > div {
        flex: 1 1;
      }
    }

    .layout-7525 {
      > section,
      > div {
        &:nth-of-type(1) {
          flex: 2 1;
        }

        &:nth-of-type(2) {
          flex: 1 2;
        }
      }
    }

    .layout-full > section {
      width: fit-content;
    }
  }
}
