@import '~aes-lesson-driver/src/mixins';

.list.page-sets {
  width: 1200px;
  div.row {
    strong, a{
      min-width: 200px;
      &:nth-child(1) {
        min-width: 60px;
      }
      &:nth-child(5) {
        min-width: 160px;
      }
    }
    button,
    a.button {
      min-width: auto;
      @include button();
      margin-right: 10px;
      align-items: center;
    }

    .assistive-listening {
      padding: 12px 15px !important;
    }

    .icev-zip,
    .aes-zip {
      margin-left: 10px;
    }
  }
}

.modal.download {
  .modal-body {
    background: $white;
    padding: $gutter;
    min-width: 500px;
    min-height: 120px;
  }
}
