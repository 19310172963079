@import url('//fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
@import '../variables';
@import '../mixins';
@import '@fortawesome/fontawesome-free/scss/fontawesome';
@import '@fortawesome/fontawesome-free/scss/v4-shims';
@import '@fortawesome/fontawesome-free/scss/solid';

body {
  margin: 0;
  padding: 0;
  font-family: $fontFamily;
  font-size: $font-size-regular;
  background: $white;
  color: $textDark;
}

.App {
  text-align: center;
}

.drag-drop-preview {
  margin: $gutter / 2;
  padding: $gutter / 2;
  background: $white;
  border: 1px solid $border;
  opacity: 0.5;
}

.sign-in {

  p {
    margin: $gutter;
  }

  > button {
    margin: $gutter;
  }

  form {
    margin: $gutter;

    label {
      display: block;
      width: 100px;
      margin-top: 12px;
    }

    input {
      width: 200px;
    }

    button {
      margin-top: 24px;
    }
  }
}

.list {
  display: flex;
  width: 400px;
  margin: auto;
  flex-direction: column;
  text-align: left;
  border: 1px solid $border;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;

    &.active{
      border: 1px solid $black;
    }

    &:nth-child(odd) {
      background-color: $grey3;
    }

    > * {
      padding: 0 10px;
    }
  }
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 101;

  .modal-body {
    position: absolute;
    top: 40%;
    left: 50%;
    width: fit-content;
    transform: translate(-50%, -40%);
    text-align: left;

    > article,
    > section {
      max-width: 80vw;
      max-height: 70vh;
      padding: $gutter * 1.5;
      overflow-y: scroll;
      background: $white;
    }

    #modal_title {
      margin-top: 0;
      color: $textBlue;
      font-weight: 500;
    }
  }

  button {
    &.modal-close {
      display: block;
      position: absolute;
      top: 12px;
      right: 12px;
      width: 1.5rem;
      height: 1.5rem;
      background: white;
      border: none;
      cursor: pointer;
    }

    &.continue,
    &.exit {
      margin: $gutter/2;
    }
  }
}
