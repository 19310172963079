@import "../mixins";

.navbar {
  display: flex;
  flex-wrap: wrap;
  padding: 6px $gutter - 6px;
  align-items: center;
  justify-content: space-between;
  background: $white;

  audio {
    height: 2em;
    margin: 0 0 0 auto;

    + label {
      margin-left: $gutter;
    }

    @include breakpoint-small {
      width: 80%;
      margin: $gutter - 6px auto -6px;
      order: 2;
    }
  }
  .pages {
    padding: 0 18px;
  }

  .navbar-link {
    margin: 0 6px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    &.exit {
      @include button()
    }

    &.save,
    &.edit {
      @include button();
    }

    &.next,
    &.prev {
      @include button();
      padding: 8px;
      svg {
        margin: 0;
      }
    }

    &.prev {
      margin-left: auto;
    }
  }
}
