@import "variables";

@mixin breakpoint-small {
  @media only screen and (max-width: 767px) {
    @content
  }
}

@mixin breakpoint-mid {
  @media only screen and (min-width: 768px) {
    @content
  }
}

@mixin button () {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  outline: none;
  border: none;
  padding: $buttonPadding;
  border-radius: $radius;
  background: $buttonGrey;
  height: fit-content;
  font-size: $font-size-regular;
  line-height: 24px;
  text-align: center;
  color: $buttonText;
  text-decoration: none;
  border: none;

  svg {
    width: 24px;
    height: 24px;
    margin-left: 10px;
  }

  &.border {
    background: none;
    border: 1px solid $buttonGrey;
    padding: 7px 19px;
  }

  &.small,
  &.icon {
    padding: 4px;
  }

  &.icon {
    background: none;
    svg {
      margin: 0;
    }
    &:hover {
      background: rgba(0,0,0, 0.055);
    }
    &:active {
      background: rgba(0,0,0, 0.15);
    }
  }

  &:hover {
    background: $buttonLightGrey;
    .tooltip {
      display: flex;
      opacity: 1;
    }
  }

  &:active {
    background: $grey3;
  }

  &:disabled {
    border: none;
    background: $buttonLightGrey;
    color: $buttonDarkGrey;
    cursor: not-allowed;
    .tooltip {
      color: $buttonDarkGrey;
      border-color: $buttonDarkGrey;
    }
  }


  &.primary,
  &.submit,
  &.add {
    background: $buttonYellow;
    &:hover {
      background: $buttonYellowHover;
    }
    &:active {
      background: $buttonYellowActive;
    }
  }

  &.secondary {
    background: $buttonGrey;
  }

  &.exit,
  &.delete {
    color: $white;
    background: $buttonWine;
    &:hover {
      background: $buttonWineHover;
    }
    &:active {
      background: $buttonWineActive;
    }
  }


  &.correct,
  &.edit,
  &.save {
    color: $white;
    background: $buttonGreen;
    &:hover {
      background: $buttonGreenHover;
    }
    &:active {
      background: $buttonGreenActive;
    }
  }

  .tooltip {
    display: none;
    opacity: 0;
    position: absolute;
    padding: 8px 12px;
    box-shadow: 0 4px 6px -2px $greyTransparent;
    background: $white;
    border: 1px solid $border;
    top: 0;
    left: 50%;
    transform: translateY(-45px) translateX(-50%);
    transition: opacity ease-in-out 0.3s;
    justify-content: center;
    width: max-content;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;


    &:before {
      content: '';
      position: absolute;
      background: $white;
      width: 8px;
      height: 8px;
      transform: rotate(225deg);
      border-left: 1px solid $border;
      border-top: 1px solid $border;
      top: 31px;
      bottom: 0;
    }
  }
}

@mixin sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
