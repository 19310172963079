@import '~aes-lesson-driver/src/variables';
@import '~aes-lesson-driver/src/mixins';

.glossary-word-editor {
  .title-row{
    display: flex;
    justify-content: space-between;
    label, button{
      margin: $gutter/2;
    }
    label {
      justify-self: flex-start;
      input {
        margin-left: $gutter/2
      }
    }
    button.save{
      justify-self: flex-end;
      @include button();
    };
  }
  .react-markdown--slate-editor{
    height: auto;
    margin-bottom: $gutter;
  }
}
