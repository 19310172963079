/* Colors */
$roseWine: #ca6e97 !default;
$regularWine: #9A1E56 !default;
$noirWine: #5C1234 !default;
$lightestBlue: #438AC5 !default;
$lighterBlue: #1A6BAC !default;
$lightBlue: #146DB6 !default;
$regularBlue: #105792 !default;
$darkBlue: #0C416D !default;
$darkerBlue: #082C49 !default;
$lighterYellow: #fbf4dc !default;
$lightYellow: #F1D474 !default;
$regularYellow: #EDC951 !default;
$darkYellow: #BEA141 !default;
$regularOrange: #D27E18 !default;
$lighterGreen: #DAEDDB !default;
$lightGreen: #44A449 !default;
$regularGreen: #1C7C32 !default;
$darkGreen: #29622C !default;
$lightBlueGreen: #58CDA8 !default;
$grey1: #F2F2F2 !default;
$grey2: #E6E6E6 !default;
$grey3: #D9D9D9 !default;
$grey4: #C0C0C0 !default;
$grey5: #9A9A9A !default;
$grey6: #737373 !default;
$grey7: #4D4D4D !default;
$grey8: #3E3E3E !default;
$grey9: #262626 !default;
$greyTransparent: rgba(33,37,41,0.1) !default;
$greyDarkTransparent: rgba(33,37,41,0.25) !default;
$white: #FFFFFF !default;

/** Text Colors */
$textBlue: $darkBlue !default;
$textDark: $grey9 !default;
$textWine: $regularWine !default;
$textGrey: $grey5 !default;
$textDarkGrey: $grey8 !default;

/** Button Colors */
$buttonLightGrey: $grey1 !default;
$buttonGrey: $grey2 !default;
$buttonGreyActive: $grey3 !default;
$buttonGreyHover: $buttonLightGrey !default;
$buttonDarkGrey: $grey4 !default;
$buttonText: $darkerBlue !default;
$buttonDark: $grey7 !default;
$buttonYellow: $regularYellow !default;
$buttonYellowActive: $darkYellow !default;
$buttonYellowHover: $lightYellow !default;
$buttonGreen: $regularGreen !default;
$buttonGreenActive: $darkGreen !default;
$buttonGreenHover: $lightGreen !default;
$buttonWine: $regularWine !default;
$buttonWineActive: $noirWine !default;
$buttonWineHover: $roseWine !default;


/**
Legacy Color names
 */
$black: $grey9 !default;
$darkGrey: $grey8 !default;
$mediumGrey: $grey6 !default;
$lightGrey: $grey5 !default;
$appBlue: $lightBlue !default;

$aesRed: $regularWine !default;
$aesOrange: $regularOrange !default;
$aesGreen: $regularGreen !default;
$aesBlue: $regularBlue !default;


$yellow: #F0FB4C !default;
$buttonYellow: #EDC951 !default;

/**
Style Variables
 */
$font-size-regular: 16px !default;
$font-size-small: 14px !default;
$fontFamily: 'Roboto', sans-serif !default;
$background: $white !default;
$border: $grey4 !default;
$questionBackground: $lighterGreen !default;
$gutter: 16px !default;
$inputPadding: 11px !default;
$buttonPadding: 8px 20px !default;
$iconButtonPadding: 11.5px 12px !default;
$radius: 5px !default;
$tipRadius: 3px !default;
