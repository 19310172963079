@import '~aes-lesson-driver/src/variables';
.page-creator,
.pageset-duplicator,
.question-creator,
.title-editor {
  padding-top: $gutter * 2 !important;

  label {
    display: block;
    padding: $gutter / 2;

    select, input {
      margin-left: $gutter / 2;
    }
  }
  .actions {
    padding: $gutter / 2;
  }
  .error {
    padding: 0 $gutter / 2 $gutter / 2;
    color: $textWine;
  }
}