@import '~aes-lesson-driver/src/variables';

section.picker {
  display: flex;
  flex-direction: column;
  .option-list{
    margin: 0;
    padding: $gutter / 2;
    list-style: none outside none;
    overflow-y: scroll;

    li {
      button {
        display: block;
        border: none;
        color: initial;
        background: none;

        &:hover{
          color: $aesBlue;
        }
      }
    }
  }
  .actions {
    input, button{
      margin: $gutter/2 $gutter/2 0;
    }
  }
}