@import '~aes-lesson-driver/src/variables';

.react-markdown--slate-editor {
  border: none;
  text-align: left;

  .react-markdown--toolbar{

    border-bottom: 1px solid $border;
    background: $lightGrey;
    z-index: 100;

    button {
      margin: 4px;
      min-width: 40px;
    }

    > .btn-group{
      margin-right: 16px;
    }

    .dropdown{
      .dropdown-menu{
        display: none;
        position: absolute;
        margin: 0;
        padding: 10px 0;
        background: $lightGrey;
        list-style: none outside none;
        border: 1px solid $border;
        border-top: none;
        a {
          display: block;
          padding: 4px 10px;
          color: inherit;
          text-decoration: none;
          cursor: pointer;
        }
      }
      &.open{
        .dropdown-menu{
          display: block;
        }
      }
    }
  }
  .react-markdown--slate-content{
    box-shadow: inset 0 0 10px rgba(0,0,0, 0.5);
    border:none;
    .react-markdown--slate-content__editor{
      padding: 20px;
    }
  }
}