@import '~aes-lesson-driver/src/variables';
@import '~aes-lesson-driver/src/mixins';

nav.navbar {
  .exit,
  .edit,
  .duplicate {
    order: 0;
  }

  audio {
    order: 1;
    & + label {
      order: 1;
    }
  }

  .prev,
  .pages,
  .next {
    order: 2;
  }
}

.page-editor {
  button,
  input,
  select,
  textarea,
  .button {
    display: inline-block;
    padding: $gutter / 2;
    border-radius: 4px;
    font-size: 1em;
    font-family: $fontFamily;
    border: 1px solid;
  }


  .actions{
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 44px;
      position: absolute;
      top: 0;
      background: $lightGrey;
      z-index: 0;
    }

    button,
    label {
      text-align: left;
      display: block;
      margin: $gutter $gutter/2;
      white-space: nowrap;
    }

    button {
      &.add {
        @include button();
        display: block;
      }
      &.delete {
        @include button();
        display: block;
      }
    }

    select,
    input{
      display: block;
      margin-top: $gutter/2;
      width: 100%;
      box-sizing: border-box;
    }

    .top-actions {
      position: relative;
      display: flex;
      justify-content: flex-end;
      z-index: 1;
      button {
        margin-top: 4px;
        border: none;
      }
    }
  }

  .editors.fill {
    .markdown {
      height: 100%;
    }
  }

  .questions {
    padding: $gutter;
    text-align: left;
    .question {
      margin: $gutter auto;
      padding: $gutter / 2;
      border: 1px solid $border;
      background: $questionBackground;

      h2 {
        display:flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0;
        font-size: 1.2em;
        button.delete{
          @include button();
        }
      }

      input,
      textarea {
        display: block;
        box-sizing: border-box;
        width: 100%;
        margin: $gutter / 3 0 $gutter;
      }

      fieldset {
        > label {
          position: relative;
          display: flex;
          width: auto;
          input[type=radio]{
            display: inline;
            width: auto;
            margin-left: 8px;
          }
          input[type=text]{
            margin: 0 8px;
          }
        }
        span {
          flex-grow: 1;
          padding: 4px $gutter * 3 4px 4px;
          button {
            @include button();
            position: absolute;
            top: 0;
            right: 0;
            padding: 3px 10px;
          }
        }
      }

      .question-actions{
        display: flex;
        justify-content: space-between;
        align-items: center;
        label {
          white-space: nowrap;
          input {
            display: inline;
            width: auto;
            margin: 0 $gutter/2
          }
        }
      }
    }
  }

  .drag-drop {
    ul {
      margin: $gutter auto;
      padding: $gutter / 2;
      width: fit-content;
      list-style: none outside none;
      border: 1px solid $border;
      background: $questionBackground;

      li{
        margin: $gutter / 2;

        input,
        textarea{
          margin: 0 $gutter/2;
        }

        textarea{
          width:400px;

          + button {
            position: relative;
            width: 70px;
            height: 75px;
            vertical-align: top;
            overflow: hidden;

            img{
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
            }
          }
        }
        button.delete{
          @include button();
          vertical-align: top;
          padding: 3px 10px;
          margin-left: $gutter/2;
        }
      }
    }

    .category{
      ul li{
        white-space: nowrap;
        input{
          margin: 0;
        }
      }
    }

    .figure{
      > div {
        display: inline-block;
        margin: $gutter auto;
      }

      .figure-wrapper {
        position: relative;
        display: inline-block;

        .term {
          position: absolute;
          padding: 10px;
          transform: translate(-50%, -50%);
          color: $yellow;
          list-style: none outside none;
          font-weight: bold;
          text-shadow: 1px 1px 0 black,
          1px -1px 0 black,
          -1px 1px 0 black,
          -1px -1px 0 black;
        }
      }
    }
  }

  .slides {
    ul li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: $gutter auto;
      padding: $gutter / 2;
      width: fit-content;
      list-style: none outside none;
      border: 1px solid $border;
      background: $questionBackground;

      button,
      span{
        margin: $gutter/2;
      }
      button.delete{
        @include button();
      }

      label{
        display: flex;
        padding: $gutter / 2;
        align-items: center;
        input {
          flex: 1;
          margin-left: $gutter / 2;
        }
      }
    }
  }
  @include breakpoint-mid() {
    .layout-5050,
    .layout-7525 {
      display: flex;
    }

    .layout-5050 {
      > section,
      > div {
        flex: 1 1;
      }
    }

    .layout-7525 {
      > section,
      > div {
        &:nth-of-type(1) {
          flex: 2 1;
        }

        &:nth-of-type(2) {
          flex: 1 2;
        }
      }
    }

    .layout-full > section {
      width: fit-content;
      margin: auto;
    }
  }
}
