@import '~aes-lesson-driver/src/variables';
@import '~aes-lesson-driver/src/mixins';

.slide-creator {
  text-align: left;
  label {
    margin: $gutter/2;
    select {
      margin-left: $gutter/2
    }
  }
  section.picker{
    .option-list{
      max-height: 35vh;
      padding: $gutter/2 0;
    }
  }

  .file-selections {
    display: flex;
    h4,
    .selected-audio,
    .selected-image {
      margin: 0;
      padding: $gutter $gutter/2;
    }
  }

  .actions{
    text-align: center;
    button.save{
      justify-self: flex-end;
      @include button();
    };
  }

}
